.monthYrTbl {
  text-transform: uppercase;
}

.nav_link {
  color: white;
  padding: 11px;
  border: 2px solid white;
  font-weight: bold;
  display: block;
  text-decoration: none;

  padding: 0.5rem 0px;
  border: 1px solid #fff;
  margin: 0px;
}

.heading {
  background-color: #b3b337;
  color: black;
  font-weight: bold;
}

marquee {
  border: 1px solid yellowgreen;
}

.dayContent {
  background-color: black;
  padding: 25px;
  border: 3px solid greenyellow;
}

.dayContent h3 {
  color: cyan;
  font-weight: bold;
}

.dayContent h6 {
  color: wheat;
}

.dayContent h6 span {
  color: red;
  font-weight: bold;
  background-color: white;
  border-radius: 40px;
  /* font-size: 20px; */
  padding: 1rem;
}

.day-success {
  background-color: #105b17;
}

.day-result span {
  background-color: #105b17;
  margin: 0px 5px 6px 5px;
}

.day-result span:hover {
  background-color: #105b17;
  color: white !important;
}

.bg-red {
  background-color: #f00;
}

.panel-custom {
  background-color: #8f89e2 !important;
  box-shadow: 0px -2px 7px 1px grey;
}

.panel-heading-custom {
  background-color: #8f89e2 !important;
  border-color: #8f89e2 !important;
}

.link-white {
  color: white;
}

.font-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.panel-title {
  text-align: center;
}

/* General Styles */
.bg-red {
  background-color: #f00;
}

.text-black {
  color: #000000;
}

.font-size-18 {
  font-size: 18px;
}

/* Panel Styles */
.panel-custom {
  background-color: #8f89e2 !important;
  box-shadow: 0px -2px 7px 1px grey;
}

.panel-heading-custom {
  background-color: #8f89e2 !important;
  border-color: #8f89e2 !important;
}

.link-white {
  color: white;
}

.font-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

/* Disclaimer Message Styles */
.disclaimer-message {
  color: #ffeb3b;
  text-align: center;
}

.satta-4-in {
  font-weight: bold;
  font-size: 18px;
}

.satta-42-in {
  font-size: 16px;
}

.satta-43-in {
  font-weight: bold;
  font-size: 18px;
}

/* Footer Styles */
/* .footer {
  text-align: center;
} */

.img-dmca {
  display: inline-block;
  width: 200px;
  height: 35px;
}

.footer-section-btn {
  /* display: flex; */
  justify-content: space-evenly;
  /* align-items: center; */
  gap: 10px;
  margin-top: 10px;
  text-wrap: nowrap;
}

.footerBtnGroup {
  margin: 0px auto;
  text-align: center;
}

.footer-btn {
  background-color: yellow;
  color: black;
  font-weight: bold;
  margin: 5px;
}

.contact,
.disclaimerr {
  background-color: #8f89e2;
}

/* =====footer css===== */
#month-select,
#year-select,
#game-select,
#go-button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-weight: bold;
}

#go-button {
  background-color: #990404;
  color: white;
  cursor: pointer;
}

#go-button:hover {
  background-color: #e95656;
  box-shadow: 9px 13px 5px #990404;
  transition: 0.5s;
}

/* ============antd table styling========== */
/* .ant-table-content thead {
  text-align: center !important;
} */

/* .ant-table-content thead tr,
.ant-table-content thead td {
  background-color: #fbc503;
  border: 1px solid #e7aa26;
  border-radius: 0px;
}

/* background-color: #fbc503;
border: 1px solid #e7aa26;
border-radius: 0px; */

.ant-table-thead>tr>td,
.ant-table-thead th {
  border-radius: 0px !important;
  border-color: #000;
  background-color: #8f89e285 !important;
  color: #0c0c0c !important;
  /* font-size: 0.6rem; */
  font-weight: bold;
  text-wrap: nowrap;
}

.ant-table-wrapper,
.ant-table-thead tr td,
.ant-table-content,
.ant-table-thead th {
  /* background: transparent !important; */
  border-bottom: 1px solid #f0f0f0;
}

.ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
.ant-table-wrapper .ant-table-thead>tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: transparent !important;
}

.ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child .ant-table-content {
  border-start-start-radius: 0px !important;
}

.ant-table-content tr {
  /* padding: 5px 0px !important; */
  text-align: center !important;

  border-right: 1px solid #ccc !important;
  vertical-align: middle !important;
}

/* .ant-table-cell.ant-table-cell th:first-child{
  background-color: green !important;
} */

.ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child {
  background-color: #2a2b3c !important;
  color: #f8f2f2 !important;
  padding: 0rem 1rem !important;
  /* #ffddff */
}

.ant-table-wrapper .ant-table-container table>tbody>tr>*:first-child {
  background-color: #8f89e2 !important;
  color: white;
  font-weight: bold;
  /* #ffddff */
}

.ant-table-content td,
.ant-table-content th {
  padding: 10px 5px !important;
  text-align: center !important;
  border-right: 1px solid #aca7a7 !important;
  vertical-align: middle !important;
}

.ant-table-content tr:nth-child(even) {
  background: #fff !important;
}

.ant-table-content tr:nth-child(odd) {
  background-color: #dbd2d2 !important;
}

/* .ant-table-content tr:nth-child(even) {
  background-color: #f9f9f9 !important;
} */

.ant-table-wrapper table {
  border-radius: 0px !important;
  /* margin-top: -6px; */
}

table tbody tr:hover {
  background-color: #f0f0f0;
  color: red;
}

/* todoooooooooooooooolist-------- */

/* App.css */

body {
  background-color: rgb(0, 0, 0) !important;
}

/* ... other styles ... */

/* To-Do List Styles */
.container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

.form-label {
  font-weight: bold;
  color: #555;
}

.form-control,
.form-select {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  font-size: 1rem;
}

.form-control:focus,
.form-select:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #004085;
}

@media (max-width: 767.98px) {
  .container {
    padding: 15px;
  }

  h1 {
    font-size: 1.5rem;
  }

  .btn-primary {
    width: 100%;
  }
}

/* ==========scrollbar======= */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #ff0000;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ff0000;
  /* border: 1px solid; */
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #eb8181;
}

/* Ensure no background color */
.container {
  padding-left: 25%;
  /* 25% gap from the left */
  padding-right: 25%;
  /* 25% gap from the right */
  background-color: #000;
}

.row {
  display: flex;
  justify-content: space-between;
  /* Distribute space between columns */
  align-items: center;
  /* Center items vertically */
}

.game-info {
  text-align: center;
}

.text-result {
  color: red !important;
  /* Bold red text */
  font-weight: bold !important;
  /* Bold text */
  /* font-size: 16px; */
  background-color: white;
  /* White background */
  border-radius: 50% !important;
  /* Round shape */
  /* padding: 16px; */
  /* Adjust padding for better visibility */
  display: inline-block;
  /* Ensure round shape and proper padding */
  /* text-align: center; */
  /* Center align text inside circle */
  margin-top: 10px;
  /* Space between game name and result */
}

.text-result p {
  margin: 0px !important;
  padding: 1.5rem 1rem;
}

/* ======= react toas ===== */
.toast-message {
  background: red !important;
  color: #fff !important;
  border: 5px solid #940505
}

.Toastify__toast-body,
.toast-message button {
  color: #fff !important
}

.Toastify__progress {
  background-color: green !important;
}

/* blink text */

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.blinking-text {
  animation: blink 2s infinite;
}


/* =====text color animation====== */
/* h2 {
  font-size: 16px;
  font-weight: bold;
  color: transparent;
  animation: effect 1s linear infinite;
  text-transform: uppercase;
} */

@keyframes effect {
  0% {
    background: linear-gradient(to left, #de2828, #410404);
    -webkit-background-clip: text;
  }

  100% {
    background: linear-gradient(to left, #ed1818, #df1f1f);
    -webkit-background-clip: text;
  }
}





/* advertisement section */
/* Main ad container with new background and color scheme */
.advertisementSection .ad-section {
  width: 92%;
  /* Covers 90%+ of screen width */
  margin: 20px auto;
  border: 3px solid #e67e22;
  /* Orange border */
  padding: 30px;
  border-radius: 12px;
  text-align: center;
  /* background-color: #fef9e7; */
  background: linear-gradient(45deg, #e5d7d4, #dd8a24);
  /* Light cream background */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

/* Heading styles */
.advertisementSection h3 {
  color: #d35400;
  /* Darker orange */
  font-size: 30px;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.advertisementSection h4 {
  color: #2c3e50;
  /* Dark gray text */
  font-size: 22px;
  margin: 10px 0;
}

/* Button styles */
.advertisementSection .ad-btn {
  /* display: inline-block; */
  /* padding: 10px; */
  /* margin: 5px; */
  /* font-size: 18px; */
  color: #fff;
  background-color: #212529;
  border: none;
  /* border-radius: 7px; */
  /* cursor: pointer; */
  /* transition: background-color 0.3s ease; */
  text-transform: uppercase;
  text-decoration: none;
}

/* Hover effect for buttons */
.advertisementSection .ad-btn:hover {
  background-color: #c0392b;
  color: #fff;
  /* Darker red on hover */
}

.fa-whatsapp {
  background: green;
  border-radius: 65%;
}

.fa-phone {
  color: green;
  /* border: solid 1px #fff; */
  box-shadow: 0px 0px 7px #fff;
  background: #fff;
  padding: 0.1rem;
  border-radius: 5px;
}